(function (ng) {
    'use strict';
    ng.module('smart-table')
        .filter('roundup',
            function() {
                return function(value) {
                    return Math.ceil(value);
                };
        })
    // filtrer true false til ja og nei
        .filter('true_false',
            function() {
                return function(text) {
                    if (text) {
                        return 'Ja';
                    }
                    return 'Nei';
                };
        })
    // filtrer stTable 
        .filter('customFilter',
            ['$filter', function($filter) {
                    var filterFilter = $filter('filter'),
                      standardComparator = function (obj, text) {

                            text = ('' + text).toLowerCase();
                            return ('' + obj).toLowerCase().indexOf(text) > -1;
                        };


                    return function customFilter(array, expression) {

                        function expectedDistinct(actual, expected) {
                            if (!actual || actual.toLowerCase() !== expected.distinct.toLowerCase()) {
                                return false;
                            }

                            return true;
                        }
                      function matchAny(actual, expected) {

                            if (expected.matchAny.all) {
                                return true;
                        }
                      
                            if (!actual) {
                                return false;
                        }
                  
                       
                            var expectedMatch = false;
                            angular.forEach(expected.matchAny.items,
                              function (itm) {
                            if (actual.toLowerCase() === itm.toLowerCase()) {
                                        expectedMatch = true;
                                    }
                                });
                
                            return expectedMatch;
                      }

                        function itIsNumberRange(actual, expected) {

                            if (expected.lower) {
                              
                                if (actual > expected.lower) {
                                    return false;
                                }
                            }
                            if (expected.higher) {
                                if (actual < expected.higher) {
                                    return false;
                                }
                            }
                            return true;
                        }

                      function itIsObject(actual, expected) {
                        
                        var isLower = expected.lower,
                                isHigher = expected.higher;

                            //exact match
                            if (expected.distinct) {
                                return expectedDistinct(actual, expected);
                            }

                            //matchAny
                        if (expected.matchAny) {
                                return matchAny(actual, expected);
                               
                            }

                            //number range
                            if (isLower || isHigher) {
                                return itIsNumberRange(actual, expected);
                        }


                        //console.log("the end");
                        if ((angular.isUndefined(actual) || actual === null) && 
                          ((expected.name && expected.name.matchAny && !expected.name.matchAny.all) ||
                            (expected.institutionType && expected.institutionType.name && expected.institutionType.name.matchAny && !expected.institutionType.name.matchAny.all)) ||
                          (expected.institution && expected.institution.name && expected.institution.name.matchAny && !expected.institution.name.matchAny.all)) {
                          return false;
                        } else {
                          return true;
                        }
                      }


                      function customComparator(actual, expected) {
                            if (typeof (actual) == typeof (true)) {
                                actual = actual.toString();
                            }

                        if (angular.isObject(expected)) {
                                return itIsObject(actual,expected);
                        } else {

                                if (angular.isObject(actual)) {
                                    // kommune
                                    if (angular.isDefined(actual.regionName)) {

                                        return ('' + actual.regionName).toLowerCase().indexOf(expected.toLowerCase()) >
                                            -1;
                                    }
                                    // telefon
                                    if (angular.isDefined(actual.number)) {

                                        return ('' + actual.number).toLowerCase().indexOf(expected.toLowerCase()) > -1;
                                    }
                                    // arbeidssted
                                    if (angular.isDefined(actual.institution) &&
                                        actual.institution !== null &&
                                        angular.isDefined(actual.institution.institutionType)) {

                                        var workplace =
                                            ('' + actual.name).toLowerCase().indexOf(expected.toLowerCase()) > -1;
                                        var institution = ('' + actual.institution.name).toLowerCase()
                                            .indexOf(expected.toLowerCase()) >
                                            -1;

                                        return workplace || institution;
                                    }
                                    // Insttype
                                    if (angular.isDefined(actual.institution) &&
                                        actual.institution !== null &&
                                        angular.isDefined(actual.institution.institutionType)) {

                                        return ('' + actual.institution.institutionType.name).toLowerCase()
                                            .indexOf(expected.toLowerCase()) >
                                            -1;
                                    }
                                    // telefon
                                    if (angular.isDefined(actual.address) && angular.isDefined(actual.name)) {

                                        return ('' + actual.name).toLowerCase().indexOf(expected.toLowerCase()) > -1;
                                    }
                                    // epost
                                    if (angular.isDefined(actual.address) && angular.isDefined(actual.type)) {

                                        return ('' + actual.address).toLowerCase().indexOf(expected.toLowerCase()) > -1;
                                    }
                                    // leder
                                    if (angular.isDefined(actual.givenName) &&
                                        angular.isDefined(actual.sureName) &&
                                        actual.isManager) {

                                        var mails = _.flatten(_.pluck(actual.mails, "address")).toString();
                                        var mail = ('' + mails).toLowerCase().indexOf(expected.toLowerCase()) > -1;
                                        var name = ('' + actual.sureName + ', ' + actual.givenName).toLowerCase()
                                            .indexOf(expected.toLowerCase()) >
                                            -1;
                                        return name || mail;
                                    }
                                }

                            }
                            return standardComparator(actual, expected);
                        }

                        angular.forEach(array,
                            function(itm) {
                                if (itm.healthCareRoles.length === 0) {
                                    itm.healthCareRoles.push({ dummy: true });
                                }
                                if (itm.networkEngagement.length > 0 &&
                                    itm.networkEngagement[0].networkEngagementFunction.length === 0) {
                                    itm.networkEngagement[0].networkEngagementFunction.push({ dummy: true });
                                }
                            });

                        var output = filterFilter(array, expression, customComparator);

                        angular.forEach(output,
                            function(itm) {
                                if (itm.healthCareRoles[0].dummy === true) {
                                    itm.healthCareRoles = [];
                                }
                                if (itm.networkEngagement.length > 0 &&
                                    itm.networkEngagement[0].networkEngagementFunction[0].dummy === true) {
                                    itm.networkEngagement[0].networkEngagementFunction = [];
                                }
                        });

              
                        return output;
                    };
                }
            ])
        .filter('titleCase',
            function() {
                return function(input) {
                    if (angular.isUndefined(input) || input === null) {
                        return '';
                    }

                    if (input.indexOf(' ') !== -1) {

                        input = input.toLowerCase();
                        var inputPieces = input.split(' ');

                        for (var i = 0; i < inputPieces.length; i++) {
                            inputPieces[i] = capitalizeString(inputPieces[i]);
                        }

                        return inputPieces.toString().replace(/,/g, ' ');
                    } else if (input.indexOf('-') !== -1) {
                      input = input.toLowerCase();
                      var inputPieces2 = input.split('-');

                      for (var i = 0; i < inputPieces2.length; i++) {
                        inputPieces2[i] = capitalizeString(inputPieces2[i]);
                      }

                      return inputPieces2.toString().replace(/,/g, ' ');
                    }
                    else {
                        input = input.toLowerCase();
                        return capitalizeString(input);
                    }

                    function capitalizeString(inputString) {
                        return inputString.substring(0, 1).toUpperCase() + inputString.substring(1);
                    }
                };
            }).filter('cut',
            function() {
                return function(value, wordwise, max, tail) {
                    if (!value) {return '';}

                    max = parseInt(max, 10);
                    if (!max) {return value;}
                    if (value.length <= max) {return value;}

                    value = value.substr(0, max);
                    if (wordwise) {
                        var lastspace = value.lastIndexOf(' ');
                        if (lastspace !== -1) {
                            //Also remove . and , so its gives a cleaner result.
                            if (value.charAt(lastspace - 1) === '.' || value.charAt(lastspace - 1) === ',') {
                                lastspace = lastspace - 1;
                            }
                            value = value.substr(0, lastspace);
                        }
                    }

                    return value + (tail || ' …');
                };
            }).filter('newlines',
            function() {
                return function(text) {
                    return text.replace(/,/g, '\n');
                };
            })
        .filter('keyboardShortcut',
            function($window) {
                return function(str) {
                    if (!str) {return;}
                    var keys = str.split('-');
                    var isOSX = /Mac OS X/.test($window.navigator.userAgent);

                    var seperator = (!isOSX || keys.length > 2) ? '+' : '';

                    var abbreviations = {
                        M: isOSX ? '⌘' : 'Ctrl',
                        A: isOSX ? 'Option' : 'Alt',
                        S: 'Shift'
                    };

                    return keys.map(function(key, index) {
                        var last = index === keys.length - 1;
                        return last ? key : abbreviations[key];
                    }).join(seperator);
                };
            });
})(angular);

